
import { computed, defineComponent, ref } from "vue";
// import { hideModal } from "@/core/helpers/dom";
// import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
// import { array } from "yup/lib/locale";
import InvoiceService from "@/core/services/car/InvoiceService";
import { notif } from "@/store/stateless/store";
interface IUploadForm {
  invoice_id: any;
  invoice_doc: any;
}
// interface IResponse {
//   data: any;
//   message: string;
//   success: boolean;
// }
export default defineComponent({
  name: "upload-invoice-document-modal",
  components: {},

  setup(props,context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadInvoiceDocumentModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let displaySuccessMsg = ref<boolean>(false);
    // let response = ref<IResponse>({
    //   data: "",
    //   message: "",
    //   success: false,
    // });
    // const formData = ref({});
    const store = useStore();

    const route = useRoute();
    const router = useRouter();
    let uploadFormData = ref<IUploadForm>({
      invoice_id: "",
      invoice_doc: "",
    });
    let file: any;

    const handleFileUpload = (event) => {
      // formData = new FormData();
      InvoiceService.clearUploadInvoiceError(null);
      uploadFormData.value.invoice_doc = event.target.files[0];
      //
    };
    const invoice_id = computed(() => {
      return store.getters.getInvoiceId;
    });

    const uploadInvoice = ref();
    const uploadInvoiceDocumet = () => {
      // console.log("sent");
      let formData = new FormData();

      // uploadFormData.value.invoice_doc = file;
      uploadFormData.value.invoice_id = invoice_id;
      formData.append("invoice_id", uploadFormData.value.invoice_id);
      formData.append("invoice_doc", uploadFormData.value.invoice_doc);
      // console.log(formData);
      if (!uploadFormData.value.invoice_doc || uploadFormData.value.invoice_doc == "") {
        InvoiceService.clearUploadInvoiceError('<p style="color: red;">Please upload file for COD / Direct</p>');
      }
      if(uploadFormData.value.invoice_doc) {
        Promise.all([InvoiceService.addInvoiceDocument(formData)]).then(
          (data) => {
            // console.log(data[0]);
            // console.log(response.success);
            displaySuccessMsg.value = true;
            uploadFormData.value = {
              invoice_id: invoice_id,
              invoice_doc: "",
            };
            uploadInvoice.value.reset();
            uploadInvoiceDocumentModalRef.value = null
            notif.simple("Approved Invoice", "success", "Your receipt has been uploaded successfully!");
            // setTimeout(() => {
            //   displaySuccessMsg.value = false
            // }, 5000);
            context.emit("callInvoiceList");
            closeModal();
            
            formData = new FormData();
            // hideModal(uploadInvoiceDocumentModalRef.value);
          }
        );
      }

      // setTimeout(() => {
      //       InvoiceService.clearUploadInvoiceError(null);
      //     }, 5000);
    };
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    const isOpen = computed(() => {
      return store.getters.getOpenUploadInvoice;
    });
    const closeModal = () => {
      InvoiceService.clearUploadInvoiceError(null);
      InvoiceService.setModalInvoice(false);
      InvoiceService.setInvoiceId(0);
      uploadInvoice.value.reset();
      uploadInvoiceDocumentModalRef.value = null
      displaySuccessMsg.value = false
      uploadFormData.value.invoice_doc = "";
      // uploadFormData.value.invoice_doc = null
    };

    const error = computed(() => {
      return store.getters.getUploadReceiptError
    })
    return {
      uploadFormData,
      error,
      invoice_id,
      closeModal,
      isOpen,
      loading,
      handleFileUpload,
      uploadInvoiceDocumet,
      disableSaveButton,
      displaySuccessMsg,
      uploadInvoiceDocumentModalRef,
      uploadInvoice,
    };
  },
});
